import React from "react";
import Herov5 from "../herov5";
import AuthorV2 from "../authorv2";
import Authorv3 from "../authorv3";
import Cta from "../cta";
import Achievementv2 from "../achievementv2";
import Contact from "../contact";
import Header from "../global/header";
import Footer from "../global/footer";

const Version05 = ({ headerv2, footer}) => {
  const {menuv2} = footer;
  return (
    <>
      <Header header={headerv2} />
      <Herov5 isBg="yes"/>
      <AuthorV2 isBg=""/>
      <Achievementv2 isBg="yes"/> 
      <Cta isBg=""/>
      <Authorv3 isBg="yes"/>
      <Contact isBg=""/>
      <Footer isBg="yes" menu={menuv2} />
    </>
  );
};
export default Version05;

export const siteLogo = {
    logo: "assets/images/logo.png",  // Add your site logo here
    alt: "emceelerene"  // Add your site name here
};
export const socialLink = {
    facebook: "https://www.facebook.com/lerenetalks/",
    linkedin: "https://www.linkedin.com/in/tongchingshuenlerene/",
    telegram: "https://t.me/EmceeLerene",
    youtube: "https://www.youtube.com/@emceelerene"
};
// export const mailchimpLink = {
//     link: (process.env.REACT_APP_MAILCHAIMP) // Add your mailchimp list link here
// };
export const contactFormAction = {
    link: (process.env.REACT_APP_CONTACTFORM)  // Add for link here from https://formspree.io
}
export const contactMap = {
    link: (process.env.REACT_APP_CONTACTMAP) // Add google map link from here: https://www.embedgooglemap.net/
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import data from "../../data/footer.json";
import { siteLogo } from "../../global";

const Footer = ({ isBg }) => {
  const { footer } = data;

  return (
    <>
      <footer
        className={`footer overflow-hidden ${isBg === "yes" ? "bg-one" : ""}`}
      >
        <div className="container">
          <div className="footer__copyright m-20px-t m-20px-b">
            <div className="row">
              <div className="col-12">
                <a href="/">
                  <img src={siteLogo.logo} alt={siteLogo.alt} style={{ display: 'block', margin: '0 auto' }} />
                </a>
                <p className="m-0 text-center footer-text">
                  {footer.contactInfo}
                </p>
                <p className="m-0 text-center footer-text">
                  Copyright &copy; {footer.copyright}
                </p>

              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
    // <!-- ========== Footer section End ========== -->
  );
};

export default Footer;

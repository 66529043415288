import React from "react";
import data from "../data/cta.json";

const Cta = ({ isBg }) => {
  const { cta } = data;
  return (
    <section
      className={`showreel section-padding ${isBg === "yes" ? "bg-one" : ""}`}
      id="showreel"
    // style={{ backgroundImage: `url("${cta.backgroundImage}")` }}
    >
      <div className="container">
        <div className="row justify-content-center showreel__inner bg-one">
          <div className="col-lg-8">
            <div className="section-title-center mb-0 text-center">
              <span
              >
                {cta.subtitle}
              </span>
              <h2
                className="display-6"
              >
                {cta.title}
              </h2>
            </div>
            <div className="cta-form-box">
              <div className="iframe-container">
                <iframe src="https://www.youtube.com/embed/yu7lSqtfwjs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen>
                </iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Cta;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import data from "../data/author.json";

const Authorv3 = ({ isBg }) => {
  const { authorv3 } = data;
  return (
    <section id="voices" className={`section-padding authorv3 ${isBg === "yes" ? "bg-one" : ""}`}>
      <div className="container">
        <div className="row">
          <div
            className="col-lg-6"
            data-aos="fade-right"
            data-aos-duration="1000"
            data-aos-delay="200"
          >
            <div className="author-image">
              <img className="img-fluid" src={authorv3.image} alt="" />
            </div>
          </div>
          <div
            className="col-lg-6"
            data-aos="fade-left"
            data-aos-duration="1000"
            data-aos-delay="200"
          >
            <div className="authorv3__content">
              <div className="authorv3__content--badge">
                {authorv3.subtitle}
              </div>

              <h3 className="display-5 mb-0">{authorv3.title}</h3>

              {/* Quote text */}
              <p className="m-3px-b title-italic">{authorv3.introDescription}</p>

              {/* Keynote Service */}
              <p className="m-30px-b">{authorv3.voiceDescription1}</p>


              {/* Emcee Service */}
              <p className="m-5px-b title-med">{authorv3.emceeTitle}</p>
              <p className="m-30px-b">{authorv3.emceeDescription}</p>

              {/* Workshop Service */}
              <p className="m-5px-b title-med">{authorv3.workTitle}</p>
              <p className="m-30px-b">{authorv3.workDescription}</p>

              {/* Point form */}
              <p className="title-med work-pointer">{authorv3.pointTitle}</p>
              <p className="work-pointer">{authorv3.pointer1}</p>
              <p className="work-pointer">{authorv3.pointer2}</p>

              {/* End Description */}
              <p className="m-30px-b end-section">{authorv3.endDescription1}</p>
              <p className="m-30px-b">{authorv3.endDescription2}</p>

            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Authorv3;

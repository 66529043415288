import React from "react";
import data from "../data/hero.json";

const Herov5 = ({ isBg }) => {
  const { herov5 } = data;
  return (
    <section
      id="hero"
      className={`hero hero__padding overflow-hidden position-relative ${
        isBg === "yes" ? "bg-one" : ""
      }`}
    >
      <div className="container">
        <div className="row gx-5 align-items-center">
          <div className="col-lg-6 mb-4 mb-lg-0">
            <div className="hero__content position-relative">
              {/* <div className="badge-text mb-2 fs-3 fw-bold">Hello,</div> */}
              <h1 className="display-4 mb-2 text-capitalize">
                Emcee {herov5.name}
              </h1>
              <div className="badge-text mb-4 fs-5 fw-bold">{herov5.title}</div>
              <p className="mb-5 fs-5">{herov5.description}</p>
            </div>
          </div>
          <div className="col-lg-6 d-flex justify-content-center">
            {/* <div className="hero__book">
              <div className="hero__book--wrapper">
                <img className="img-fluid" src={herov5.bookImage} alt="" />
              </div>
            </div> */}
            <div className="hero__author text-center">
              <div className="hero__author--inner">
                <div
                  className="hero__author--inner--pic  d-flex align-items-end justify-content-center"
                  style={{ backgroundImage: `url(${herov5.writerImage})` }}
                ></div>
                {/* <div className="frame frame-1"></div>
                <div className="frame frame-2"></div>
                <div className="frame frame-3"></div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Herov5;
